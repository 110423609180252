import cx from 'classnames';

import BgImage from 'src/components/BgImage/BgImage';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';

import styles from './NameColumnContent.module.scss';

const NameColumnContent = ({
  val: member,
}: {
  val: CommunityMember | User;
}) => {
  const avatarStr = member.email ? member.email[0].toUpperCase() : ' ';

  const communityMemberFullName = `${member.first_name || ''} ${
    member.last_name || ''
  }`;

  return (
    <div className="d-flex justify-content-start">
      {member.profile_image ? (
        <BgImage
          src={member.profile_image}
          className={cx('rounded-circle me-3')}
          width="40px"
          height="40px"
          vignette
        />
      ) : (
        <div
          className={cx(
            'rounded-circle me-3 d-flex justify-content-center align-items-center',
            styles.avatar
          )}
        >
          {avatarStr}
        </div>
      )}
      <div className="d-flex flex-column text-start">
        <span className={styles.name}>{communityMemberFullName}</span>
        <span>{member.email}</span>
      </div>
    </div>
  );
};

export default NameColumnContent;
