import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Button from 'src/components/core/Button/Button';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';

import styles from './RemoveMemberButton.module.scss';

interface RemoveMemberButtonProps {
  member: User | CommunityMember;
  useIcon?: boolean;
  communityTable?: boolean;
}

const RemoveMemberButton = ({
  member,
  useIcon,
  communityTable,
}: RemoveMemberButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const memberFullName = `${member.first_name || ''} ${member.last_name || ''}`;
  const [removeTooltip, setRemoveTooltip] = React.useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] =
    React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<UserTransactions>();
  const transactions = useSelector(adminSelectors.getUserTransactions);
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const isOwner =
    useSelector((state) =>
      (state as StoreState).admin.users.filter(({ id }) => id === member.id)
    )[0]?.roles.filter(
      (role) =>
        role.organization_id === organizationId &&
        role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase()
    ).length ?? false;
  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  React.useEffect(() => {
    let matchingTransaction: UserTransactions;
    if (transaction) {
      matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];
      // TODO: Error Handling
    }
    return () => {
      if (matchingTransaction) {
        setConfirmationModal(false);
        setQuery({ userId: undefined, page: undefined });
      }
    };
  }, [transactions, transaction, setQuery]);

  const onDeleteClick = () => {
    const transaction = generateTransaction({
      type: communityTable ? 'remove_community_member' : 'remove',
      data: { id: member.id },
    });
    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  const modalText = communityTable
    ? 'admin.tab_community_member_remove_tooltip'
    : 'admin.tab_team_member_remove_tooltip';

  const RemoveConfirmationModal = (
    <ConfirmationModal
      isLoading={!!transaction}
      isOpen={confirmationModal}
      handleCloseModal={() => setConfirmationModal(false)}
      text={{
        description: t(modalText, {
          name: memberFullName,
        }),
        confirm: t('admin.remove_member'),
      }}
      confirm={onDeleteClick}
    />
  );

  //Return Logic
  if (isOwner) {
    return <></>;
  } else if (useIcon) {
    return (
      <>
        <Button
          outline
          className="shadow-none"
          icon={['fas', 'trash']}
          id={`remove-team-member-${member.id}`}
          aria-label={t('admin.tab_team_member_remove_tooltip', {
            name: memberFullName,
          })}
          onClick={() => setConfirmationModal(true)}
          data-testid={`remove-team-member-${member.id}`}
        />
        <Tooltip
          placement="bottom"
          hideArrow={true}
          isOpen={removeTooltip}
          innerClassName={cx(styles.tooltip)}
          target={`remove-team-member-${member.id}`}
          toggle={() => setRemoveTooltip((self) => !self)}
          data-testid={`tooltip-remove-member-${member.id}`}
        >
          {t('admin.tab_team_member_remove_tooltip', { name: memberFullName })}
        </Tooltip>
        {RemoveConfirmationModal}
      </>
    );
  } else {
    return (
      <>
        <Button
          className={styles.removeButton}
          id={`remove-team-member-${member.id}`}
          onClick={() => setConfirmationModal(true)}
          data-testid="remove-member-button"
        >
          {t('admin.remove')}
        </Button>
        <Tooltip
          placement="bottom"
          hideArrow={true}
          isOpen={removeTooltip}
          innerClassName={cx(styles.tooltip)}
          target={`remove-team-member-${member.id}`}
          toggle={() => setRemoveTooltip((self) => !self)}
          data-testid={`tooltip-remove-member-${member.id}`}
        >
          {communityTable
            ? t('admin.tab_community_member_remove_tooltip', {
                name: memberFullName,
              })
            : t('admin.tab_team_member_remove_tooltip', {
                name: memberFullName,
              })}
        </Tooltip>
        {RemoveConfirmationModal}
      </>
    );
  }
};

export default RemoveMemberButton;
