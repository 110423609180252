import 'focus-visible';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { Globals as ReactSpringGlobals } from 'react-spring';
import { createTheme, ThemeProvider } from '@mui/material';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import moment, { Moment } from 'moment-timezone';
import { compose } from 'redux';

import AdminPage, {
  canAccessAdminPage,
} from 'src/components/AdminPage/AdminPage';
import CollectionTopicsPage from 'src/components/CollectionTopicsPage/CollectionTopicsPage';
import ConversationRoute from 'src/components/ConversationRoute/ConversationRoute';
import ConversationsRoute from 'src/components/ConversationsRoute/ConversationsRoute';
import ConversationUploadPage from 'src/components/ConversationUploadPage/ConversationUploadPage';
import HighlightRoute from 'src/components/HighlightRoute/HighlightRoute';
import HighlightsRoute from 'src/components/HighlightsRoute/HighlightsRoute';
import HomeRoute from 'src/components/HomeRoute/HomeRoute';
import GuardianPage from 'src/components/LegalPage/GuardianPage';
import LegalPage from 'src/components/LegalPage/LegalPage';
import MainNav from 'src/components/MainNav/MainNav';
import NotAuthorized from 'src/components/NotAuthorized/NotAuthorized';
import PrivateRoute from 'src/components/PrivateRoute/PrivateRoute';
import PublicCollectionPage from 'src/components/PublicCollectionPage/PublicCollectionPage';
import ConfirmSignupRoute from 'src/components/RegistrationRoutes/ConfirmSignupRoute/ConfirmSignupRoute';
import GuardianPermissionRoute from 'src/components/RegistrationRoutes/GuardianPermissionRoute/GuardianPermissionRoute';
import RegistrationRoutes from 'src/components/RegistrationRoutes/RegistrationRoutes';
import SearchPage from 'src/components/SearchPage/SearchPage';
import SettingsPage from 'src/components/SettingsPage/SettingsPage';
import { GlobalAudioContextProvider } from 'src/contexts/GlobalAudioContext';
import { AnalyticsProvider } from 'src/Providers/AnalyticsProvider';
import authSelectors from 'src/redux/auth/auth-selectors';
import { logout } from 'src/redux/auth/auth-slice';
import { StoreState } from 'src/redux/store';
import { User, UserPreferences } from 'src/types/auth';
import { addIcons } from 'src/util/icons';
import { prefersReducedMotion } from 'src/util/styles';
import {
  getAudioUploadPermission,
  getInsightsRoles,
  hasAnyRole,
  hasPermission,
  isAnonymous,
  isAuthorized,
} from 'src/util/user';
import { FlagProvider } from '../../Providers/FlagProvider';
import CatalogPage from '../Insights/Catalog/CatalogPage';
import CatalogsPage from '../Insights/Catalogs/CatalogsPage';
import RecordingCompleted from '../RecordingRoom/RecordingComplete/RecordingComplete';
import RecordingRoom from '../RecordingRoom/RecordingRoom';
import RecordingRoomSetUp from '../RecordingRoom/RecordingRoomSetUp/RecordingRoomSetUp';
import { Nested } from './Nested';

interface StateProps {
  isAuthenticated: boolean;
  user: User;
  userEmail: string | undefined;
  profile: UserPreferences | undefined;
  termsEffectiveDate: Moment | undefined;
}

/** Map state from redux to the components props */
const mapStateToProps = (state: StoreState): StateProps => ({
  isAuthenticated: authSelectors.isAuthenticated(state),
  user: authSelectors.getUser(state),
  userEmail: authSelectors.getUserEmail(state),
  profile: authSelectors.getUserPreferences(state),
  termsEffectiveDate: authSelectors.getTermsEffectiveDate(state),
});

type Props = RouteComponentProps & StateProps & DispatchProp;

const App = (props: Props) => {
  const handleLogOut = () => {
    const { dispatch } = props;
    dispatch(logout());
  };

  React.useEffect(() => {
    // react-spring animations skipped if reduced motion is preferred
    const reduceMotion = prefersReducedMotion();
    if (reduceMotion) {
      ReactSpringGlobals.assign({
        skipAnimation: true,
      });
    }
  }, []);

  const { i18n } = useTranslation();
  React.useEffect(() => {
    // When a language change is detected (on load or through settings),
    // make a change in i18n and moment. If no language provided, default
    // to en-US
    if (!props.user.language) {
      moment.locale('en-US');
      i18n.changeLanguage('en-US');
    } else {
      moment.locale(props.user.language);
      i18n.changeLanguage(props.user.language);
    }
  }, [props.user.language, i18n]);

  const { isAuthenticated, termsEffectiveDate, user, userEmail } = props;

  if (isAuthenticated && !isAuthorized(user)) {
    return <NotAuthorized username={userEmail} />;
  }

  // adds fontawesome icons for usage in entire app
  addIcons();

  const location = window.location;
  // check if user has agreed to terms since it was last updated
  // only show if we have a defined terms effective date
  const showLegalPage =
    termsEffectiveDate &&
    (user.lvn_terms_agreed
      ? !termsEffectiveDate.isBefore(user.lvn_terms_agreed)
      : true);

  if (!isAnonymous(user) && showLegalPage) {
    // check if user is a minor who needs guardian permission
    const showGuardianPermissionRoute =
      user.is_minor &&
      (user.guardian_email === '' ||
        location.pathname.startsWith('/guardian-permission'));
    return (
      <GlobalAudioContextProvider>
        <SkipNavLink />
        <div className="App App-earshot">
          <MainNav
            isAuthenticated={isAuthenticated}
            onLogOut={handleLogOut}
            userEmail={userEmail}
          />
          <SkipNavContent>
            <Switch>
              <PrivateRoute
                path="/legal"
                render={(props) => <LegalPage showAgreeAction {...props} />}
                exact
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/guardian-permission"
                render={(props) => <GuardianPermissionRoute {...props} />}
                isAuthenticated={isAuthenticated}
              />
              {showGuardianPermissionRoute ? (
                <Redirect
                  to={{
                    pathname: '/guardian-permission',
                    state: { referrer: window.location.pathname },
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: '/legal',
                    state: { referrer: window.location.pathname },
                  }}
                />
              )}
            </Switch>
          </SkipNavContent>
        </div>
      </GlobalAudioContextProvider>
    );
  }

  return (
    <GlobalAudioContextProvider>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: '#4f29b7',
            },
          },
          typography: {
            fontFamily: [
              'proxima-nova',
              '"Proxima Nova"',
              '-apple-system',
              'BlinkMacSystemFont',
              '"Segoe UI"',
              'Roboto',
              '"Helvetica Neue"',
              'Arial',
              'sans-serif',
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
              '"Noto Color Emoji"',
            ].join(','),
          },
        })}
      >
        <SkipNavLink />
        <div className="App App-earshot">
          <MainNav
            isAuthenticated={isAuthenticated}
            onLogOut={handleLogOut}
            userEmail={userEmail}
          />
          <SkipNavContent>
            <Switch>
              {/* Redirects for Legacy Insights */}
              <Route
                exact
                path="/insights/catalogs"
                render={() => <Redirect to="/insights" />}
              />
              <Route
                exact
                path="/insights/catalogs/:catalogId"
                render={(props: RouteComponentProps<{ catalogId: string }>) => (
                  <Redirect
                    to={`/insights/catalog/${props.match.params.catalogId}`}
                  />
                )}
              />
              {/* End of Legacy Redirects */}

              <Route
                path="/conversation/:conversationId"
                render={(
                  props: RouteComponentProps<{ conversationId: string }>
                ) => <ConversationRoute {...props} />}
              />
              <PrivateRoute
                path="/highlights/"
                render={(props) => <HighlightsRoute {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <Route
                path="/highlight/:highlightId"
                render={(
                  props: RouteComponentProps<{ highlightId: string }>
                ) => <HighlightRoute {...props} />}
              />
              <PrivateRoute
                path="/conversations/"
                render={() => <ConversationsRoute />}
                isAuthenticated={isAuthenticated}
              />
              <Route
                path="/collection/:collectionId"
                render={(
                  props: RouteComponentProps<{ collectionId: string }>
                ) => {
                  const collectionId = +props.match.params.collectionId;
                  const collection = user.collections.find(
                    (c) => c.id === collectionId
                  );
                  if (collection?.privacy_level === 'public') {
                    return <PublicCollectionPage {...props} />;
                  }
                  return <Redirect to="/" />;
                }}
              />
              <PrivateRoute
                path="/search"
                render={(props) => <SearchPage {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/settings"
                render={(props) => <SettingsPage {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/conversation-upload"
                render={(props) => {
                  // redirect users who do not have the create audio upload permission
                  if (
                    !hasPermission(user, getAudioUploadPermission('create'))
                  ) {
                    return <Redirect to="/" />;
                  }
                  const mergedProps = {
                    ...props,
                    ...{ uploadType: 'upload' },
                  };
                  return (
                    <ConversationUploadPage
                      {...mergedProps}
                      key={'conversation-upload-page'}
                    />
                  );
                }}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/conversation-record"
                render={(props) => {
                  // redirect users who do not have the record audio permission
                  if (
                    !hasPermission(user, getAudioUploadPermission('create'))
                  ) {
                    return <Redirect to="/" />;
                  }

                  const mergedProps = {
                    ...props,
                    ...{ uploadType: 'recording' },
                  };
                  return (
                    <ConversationUploadPage
                      {...mergedProps}
                      key={'conversation-record-page'}
                    />
                  );
                }}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/recording-room-setup"
                render={() => {
                  // redirect users who do not have the record audio permission
                  if (
                    !hasPermission(user, getAudioUploadPermission('create'))
                  ) {
                    return <Redirect to="/" />;
                  }

                  return <RecordingRoomSetUp />;
                }}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/recording-room"
                render={() => {
                  // redirect users who do not have the record audio permission
                  if (
                    !hasPermission(user, getAudioUploadPermission('create'))
                  ) {
                    return <Redirect to="/" />;
                  }

                  return <RecordingRoom />;
                }}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/recording-complete"
                render={() => {
                  // redirect users who do not have the record audio permission
                  if (
                    !hasPermission(user, getAudioUploadPermission('create'))
                  ) {
                    return <Redirect to="/" />;
                  }

                  return <RecordingCompleted />;
                }}
                isAuthenticated={isAuthenticated}
              />
              <Route
                path="/topics"
                render={(props) => <CollectionTopicsPage {...props} />}
              />

              <PrivateRoute
                path="/insights"
                exact
                render={() => {
                  // redirect users who do not have read draft conversations or create/update collections perm
                  const insightsRoles = getInsightsRoles(user);
                  const canViewInsights = hasAnyRole(user, insightsRoles);
                  if (!canViewInsights) {
                    return <Redirect to="/" />;
                  }
                  return <CatalogsPage />;
                }}
                isAuthenticated={isAuthenticated}
              />
              <Route
                path="/insights/catalog/:catalogId"
                render={() => {
                  // redirect users who do not have read draft conversations or create/update collections perm
                  const insightsRoles = getInsightsRoles(user);
                  const canViewInsights = hasAnyRole(user, insightsRoles);
                  if (!canViewInsights) {
                    return <Redirect to="/" />;
                  }
                  return <CatalogPage />;
                }}
              />
              <PrivateRoute
                path="/legal"
                exact
                render={(props) => <LegalPage {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/admin/"
                render={(props) => {
                  // redirect users who do not have read draft conversations or create/update collections perm
                  const canViewAdmin = canAccessAdminPage(user);

                  if (!canViewAdmin) {
                    return <Redirect to="/" />;
                  }
                  return <AdminPage {...props} />;
                }}
                isAuthenticated={isAuthenticated}
              />

              <PrivateRoute
                exact
                path="/"
                render={(props) => <HomeRoute {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/scheduled-conversations"
                render={() => {
                  return <Redirect to="/" />;
                }}
                isAuthenticated={isAuthenticated}
              />
              <PrivateRoute
                path="/confirm-signup"
                isAuthenticated={isAuthenticated}
                render={(props) => <ConfirmSignupRoute {...props} />}
              />
              {/* routes for regsitration form */}
              <Route
                path="/register"
                render={(props) => <RegistrationRoutes {...props} />}
              />
              <PrivateRoute
                path="/guardian-permission"
                render={(props) => <GuardianPermissionRoute {...props} />}
                isAuthenticated={isAuthenticated}
              />
              <Route
                path="/legal/guardian"
                render={(props) => <GuardianPage {...props} />}
              />
              {/* handle no match, typically 404 */}
              <PrivateRoute
                render={(props) => <HomeRoute {...props} />}
                isAuthenticated={isAuthenticated}
              />
            </Switch>
          </SkipNavContent>
        </div>
      </ThemeProvider>
    </GlobalAudioContextProvider>
  );
};

const providerWrappers = [FlagProvider];

const WrappedApp = (props: Props) => {
  return (
    <React.Suspense fallback="...is loading">
      <AnalyticsProvider>
        <Nested components={providerWrappers}>
          <App {...props} />
        </Nested>
      </AnalyticsProvider>
    </React.Suspense>
  );
};

// @ts-ignore (there is some problem with `compose`)
export default compose(withRouter, connect(mapStateToProps))(WrappedApp);
// NOTE: react-redux typing needs update https://github.com/DefinitelyTyped/DefinitelyTyped/issues/17829
