import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { Organization, User, UserRequestPayload } from 'src/types/auth';
import { Collection } from 'src/types/collection';
import { ServerError } from 'src/types/core';
import { Community, CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';

// selectors
export const selectors = {
  getUsers: (state: StoreState): User[] => state.admin.users,
  isLoadingUsers: (state: StoreState): boolean => state.admin.usersLoading,
  getCurrentUser: (state: StoreState): User | undefined =>
    state.admin.users.filter(({ id }) => id === state.admin.currentUserId)[0],
  getUsersError: (state: StoreState): ServerError | undefined =>
    state.admin.userFetchError,
  getUserTransactions: (state: StoreState): UserTransactions[] =>
    state.admin.transactions,
  getUserTransactionsById: (
    state: StoreState,
    userId?: number
  ): UserTransactions[] =>
    state.admin.transactions.filter(
      (transaction) =>
        (transaction.data as Partial<UserRequestPayload>).id === userId
    ),
  getOrganizationId: (state: StoreState): number | undefined =>
    state.admin.organization?.id,
  getActiveOrganization: (state: StoreState): Organization | undefined =>
    state.admin.organization,
  getCollections: (state: StoreState): Collection[] => state.admin.collections,
  getCommunities: (state: StoreState): Community[] => state.admin.communities,
  getActiveCommunity: (state: StoreState): Community | undefined =>
    state.admin.community,
  getCommunityMembers: (state: StoreState): CommunityMember[] =>
    state.admin.communityMembers.community_users,
  getNonCommunityMembers: (state: StoreState): CommunityMember[] =>
    state.admin.communityMembers.non_community_users,
  getCollectionsError: (state: StoreState): ServerError | undefined =>
    state.admin.collectionsFetchError,
  isAdminOrOwner: (state: StoreState): boolean =>
    !!state.admin.users
      .filter(({ id }) => id === state.admin.currentUserId)[0]
      ?.roles.filter(
        (role) =>
          role.organization_id === state.admin.organization?.id &&
          (role.role_type.toLowerCase() ===
            OrganizationRole.admin.toLowerCase() ||
            role.role_type.toLowerCase() ===
              OrganizationRole.owner.toLowerCase())
      )[0],
  isSenseMaker: (state: StoreState): boolean =>
    !!state.admin.users
      .filter(({ id }) => id === state.admin.currentUserId)[0]
      ?.roles.filter(
        (role) =>
          role.organization_id === state.admin.organization?.id &&
          role.role_type.toLowerCase() ===
            OrganizationRole.sensemaker.toLowerCase()
      )[0],
};

export default selectors;
