import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';

import styles from './AddCommunityMemberButton.module.scss';

interface AddCommunityMemberButtonProps {
  member: CommunityMember;
  useIcon?: boolean;
}

const AddCommunityMemberButton = ({
  member,
  useIcon,
}: AddCommunityMemberButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const organizationId = useSelector(adminSelectors.getOrganizationId);

  const [transaction, setTransaction] = React.useState<UserTransactions>();
  const transactions = useSelector(adminSelectors.getUserTransactions);

  const isOwner =
    useSelector((state) =>
      (state as StoreState).admin.users.filter(({ id }) => id === member.id)
    )[0]?.roles.filter(
      (role) =>
        role.organization_id === organizationId &&
        role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase()
    ).length ?? false;

  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  React.useEffect(() => {
    if (transaction) {
      const matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];

      if (!matchingTransaction) {
        setTransaction(undefined);
      } else {
        if (matchingTransaction.error) {
          // TODO: Error Handling
        }

        setTransaction(matchingTransaction);
      }
    }
  }, [transactions, transaction, setQuery]);

  const onAddClicked = () => {
    const transaction = generateTransaction({
      type: 'add_community_member',
      data: { id: member.id },
    });
    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  const buttonText = t('common.add');

  const testId = `add-community-member-button-${member.id}`;

  const loading = transaction && !transaction.error;

  //Return Logic
  if (isOwner) {
    return <></>;
  } else if (useIcon) {
    return (
      <Button
        outline
        className="shadow-none"
        icon={['fas', 'trash']}
        aria-label={'Add Community Member'}
        onClick={onAddClicked}
        data-testid={testId}
      />
    );
  } else {
    return (
      <Button
        className={styles.addButton}
        aria-label={'Add Community Member'}
        onClick={onAddClicked}
        data-testid={testId}
      >
        {loading ? <LoadingSpinner active size="sm" /> : buttonText}
      </Button>
    );
  }
};

export default AddCommunityMemberButton;
