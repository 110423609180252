import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { StringParam, useQueryParams } from 'use-query-params';

import { sendActivationEmail } from 'src/api/api';
import Button from 'src/components/core/Button/Button';
import LoadingSpinner from 'src/components/core/LoadingSpinner/LoadingSpinner';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';
import UsertStatusTooltip from '../Tooltips/UserStatusTooltip';

import styles from './UserStatus.module.scss';

interface UserstatusProps {
  user: User | CommunityMember;
}

const UserStatus = ({ user }: UserstatusProps) => {
  const { t } = useTranslation();
  const ACTIVE_THRESHOLD = 30;
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const [emailStatus, setEmailStatus] = React.useState<
    undefined | 'sent' | 'loading' | 'error'
  >();
  const [query] = useQueryParams({
    tab: StringParam,
  });
  const { tab: activeTab } = query;
  const isActive = React.useMemo(
    () =>
      (user.last_access &&
        moment().diff(user.last_access, 'days') < ACTIVE_THRESHOLD) ||
      (user.last_login &&
        moment().diff(user.last_login, 'days') < ACTIVE_THRESHOLD),
    [user.last_access, user.last_login]
  );
  const onEmailClick = () => {
    // Since sending the email is not part of a user update, it exists outside of the transaction process
    if (organizationId) {
      setEmailStatus('loading');
      sendActivationEmail(organizationId, user.id)
        .then(() => setEmailStatus('sent'))
        .catch(() => setEmailStatus('error'));
    }
  };

  const ButtonContent = React.useMemo(() => {
    switch (emailStatus) {
      case 'sent':
        return t('admin.tab_team_member_email_success');

      case 'loading':
        return <LoadingSpinner active size="md" />;
      case 'error':
        return t('admin.tab_team_member_email_failure');
      default:
        return t('admin.tab_team_member_email');
    }
  }, [emailStatus, t]);
  //This user has not accessed LVN in the past 20(?) days

  return (
    <UsertStatusTooltip
      width={'400px'}
      isRegistered={!!user.last_login}
      text={
        // TODO: remove this special case for `comunity_members` once
        // the BE api is returning the full user obj
        activeTab === 'community_members'
          ? ''
          : isActive
          ? t('admin.tab_team_member_active_tooltip', {
              relativeTime: user.last_access
                ? user.last_access.fromNow()
                : user.last_login?.fromNow(),
            })
          : t('admin.tab_team_member_inactive_tooltip', {
              threshold: ACTIVE_THRESHOLD,
            })
      }
      id={`user-status-content-${user.id}`}
    >
      <div className={'d-flex align-items-center'}>
        {user.last_login ? (
          <span className={isActive ? styles.activated : styles.unActivated}>
            {isActive
              ? t('admin.tab_team_member_status_active').toUpperCase()
              : t('admin.tab_team_member_status_inactive').toUpperCase()}
          </span>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.text}>PENDING</span>
            <Button
              className={styles.button}
              onClick={onEmailClick}
              disabled={!!emailStatus}
              data-testid="email-activate-btn"
            >
              {ButtonContent}
            </Button>
          </div>
        )}
      </div>
    </UsertStatusTooltip>
  );
};

export default UserStatus;
