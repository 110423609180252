import cx, { Argument } from 'classnames';

import styles from './Table.module.scss';

export type TableProps<T = any> = {
  // required props
  columns: ColumnConfig<T>[];
  values: T[];
  valToKey: (val: T) => React.Key;
  testId: string;
  // styling props
  tableStyles?: React.CSSProperties;
  tableClassNames?: Argument;
  headerStyles?: React.CSSProperties;
  headerClassNames?: Argument;
  rowStyles?: React.CSSProperties;
  rowClassNames?: Argument;
  // arbitrary settings for special behaviors of some tables
  collapsableHeader?: boolean;
  /**
   * Note: if you use this prop it is on the caller to make sure
   * that interactive column content call e.stopPropagation()
   * to avoid triggering this.
   */
  onRowClick?: (val: T) => void;
};

export type ColumnConfig<T = any> = {
  id: string;
  headerLabel: string | React.FC;
  content: React.FC<{ val: T }>;

  /**
   * Correspond to bootsrap column sizes. Defaults to even spacing.
   */
  widthFraction?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  fitContent?: boolean;
  alignRight?: boolean;
};

export const Table = ({
  columns,
  values,
  valToKey,
  tableStyles,
  tableClassNames,
  headerStyles,
  headerClassNames,
  rowStyles,
  rowClassNames,
  collapsableHeader,
  onRowClick,
  testId,
}: TableProps) => {
  return (
    <div
      className={cx('container-fluid', styles.table, tableClassNames)}
      style={tableStyles}
      data-testid={testId}
    >
      <TableHeader
        style={headerStyles}
        classNames={headerClassNames}
        columns={columns}
        collapsableHeader={collapsableHeader}
      />
      {values.map((val, i) => (
        <TableRow
          key={valToKey(val)}
          style={rowStyles}
          classNames={rowClassNames}
          valToKey={valToKey}
          onRowClick={onRowClick}
          val={val}
          columns={columns}
          isFirst={i == 0}
        />
      ))}
    </div>
  );
};

type TableHeaderProps<T = any> = {
  columns: ColumnConfig<T>[];
  style?: TableProps<T>['headerStyles'];
  classNames?: TableProps<T>['headerClassNames'];
  collapsableHeader?: TableProps<T>['collapsableHeader'];
};

const TableHeader = ({
  columns,
  style,
  classNames,
  collapsableHeader,
}: TableHeaderProps) => {
  return (
    <div className={cx('row', classNames, styles.headerRow)} style={style}>
      {columns.map((col) => {
        const colClass = colToClassname(col);

        const headerLabel =
          typeof col.headerLabel == 'function' ? (
            <col.headerLabel />
          ) : (
            col.headerLabel
          );

        return (
          <div className={cx(colClass)} key={col.id}>
            {headerLabel}
          </div>
        );
      })}
    </div>
  );
};

type TableRowProps<T = any> = {
  val: T;
  columns: ColumnConfig<T>[];
  valToKey: TableProps<T>['valToKey'];
  onRowClick: TableProps<T>['onRowClick'];
  style?: TableProps<T>['rowStyles'];
  classNames?: TableProps<T>['rowClassNames'];
  isFirst?: boolean;
};

const TableRow = ({
  val,
  columns,
  valToKey,
  onRowClick,
  style,
  classNames,
  isFirst,
}: TableRowProps) => {
  return (
    <div
      className={cx(
        'row',
        'align-items-center',
        styles.row,
        { [styles.first]: isFirst },
        classNames
      )}
      style={style}
      onClick={() => onRowClick?.(val)}
    >
      {columns.map((column) => (
        <TableRowSection
          key={`${valToKey(val)}-${column.id}`}
          column={column}
          val={val}
        />
      ))}
    </div>
  );
};

type TableRowSectionProps<T = any> = { val: T; column: ColumnConfig<T> };

const TableRowSection = ({ val, column }: TableRowSectionProps) => {
  const Content = column.content;
  const colClass = colToClassname(column);

  return (
    <div
      className={cx(styles.section, colClass, {
        [styles.alignRight]: column.alignRight,
      })}
    >
      <Content val={val} />
    </div>
  );
};

// helpers
const colToClassname = (col: ColumnConfig) => {
  return [
    col.widthFraction
      ? `col-${col.widthFraction}`
      : col.fitContent
      ? `col-auto`
      : `col`,
    'p-0',
  ];
};

export const ConsumeEvents = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{ width: 'fit-content' }} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
};
